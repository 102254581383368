<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-left>
      <v-flex xs12 text-left pb-3>
        <span style="font-family: poppinssemibold; font-size: 20px"
          >DASHBOARD
        </span>
      </v-flex>
      <v-flex
        xs12
        md4
        lg3
        px-1
        pt-1
        text-center
        v-for="(item, i) in cards"
        :key="i"
      >
        <v-card height="100px" style="border-left: 10px solid #005f32">
          <router-link :to="item.route">
            <v-layout wrap fill-height class="text" px-3>
              <v-flex lg10 xs12 align-self-center text-left>
                <span>{{ item.name }}</span> <br />
                <span style="font-family: poppinssemibold; font-size: 25px">
                  {{ item.value }}</span
                >
              </v-flex>
              <v-flex xs2 align-self-center text-left>
                <v-icon large color="#005f32">{{ item.icon }}</v-icon>
              </v-flex>
            </v-layout>
          </router-link>
        </v-card>
      </v-flex>
      <v-flex xs12 text-left pb-3 pt-6>
        <span style="font-family: poppinssemibold; font-size: 20px"
          >WTI FINANCIAL REPORT</span
        >
      </v-flex>
      <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
        <v-select
          item-color="#005f32"
          :items="years"
          item-text="fyear"
          item-value="_id"
          dense
          label="Year"
          v-model="year1"
        >
        </v-select>
      </v-flex>
      <!-- <v-flex xs12 md2 text-right pb-3 pt-8 pl-2 pr-1>
  <v-select
    item-color="#005f32"
    :items="months"
    item-text="fyear"
    item-value="_id"
    dense
    label="Month"
    v-model="mnth"
  >
  </v-select>
</v-flex>
<v-flex xs12 md2 text-right pb-3 pt-8 pl-2 pr-1>
  <v-autocomplete
    clearable
    item-color="#005f32"
    color="#005f32"
    :items="organisationList"
    dense
    label="Organization"
    v-model="ORG"
    item-text="name"
    item-value="_id"
  >
  </v-autocomplete>
</v-flex> -->
      <!-- <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
  <v-select
    item-color="#005f32"
    :items="years"
    item-text="fyear"
    item-value="_id"
    dense
    label="Year"
    v-model="year2"
  >
  </v-select>
</v-flex>
<v-flex xs12 md2 text-right pb-3 pt-8 pl-2 pr-1>
  <v-autocomplete
    clearable
    item-color="#005f32"
    color="#005f32"
    :items="organisationList"
    dense
    label="Organization"
    v-model="orgId1"
    item-text="name"
    item-value="_id"
  >
  </v-autocomplete>
</v-flex> -->
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card elevation="0" shaped color="#D61A3C" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Amount to be Raised : <br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ piedata2.toBeRaised }}</span
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 text-left>
            <v-card elevation="5" shaped color="#0E26B1" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span
                    style="
                      font-family: poppinsregular;
                      color: white;
                      font-size: 15px;
                    "
                  >
                    Committed but not Confirmed : <br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ piedata2.notconfirmed }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card elevation="10" shaped color="#FFA500" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Committed and Confirmed : <br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ piedata2.committed }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card
              elevation="10"
              shaped
              color="rgb(0, 77, 26)"
              class="white--text"
            >
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Amount Recieved :<br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ piedata2.amountAchieved }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 v-if="flag">
        <div>
          <center>
            <apexchart
              type="pie"
              width="480"
              :options="chartOptions2"
              :series="series2"
            >
            </apexchart>
          </center>
        </div>
      </v-flex>

      <v-flex xs12 text-left pb-3 pt-6>
        <span style="font-family: poppinssemibold; font-size: 20px"
          >DONOR BASED REPORTS
        </span>
      </v-flex>
      <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
        <v-select
          item-color="#005f32"
          :items="years"
          item-text="fyear"
          item-value="_id"
          dense
          label="Year"
          v-model="year2"
        >
        </v-select>
      </v-flex>
      <v-flex xs12 md2 text-right pb-3 pt-8 pl-2 pr-1>
        <v-select
          item-color="#005f32"
          :items="months"
          item-text="fyear"
          item-value="_id"
          dense
          label="Month"
          v-model="mnth"
        >
        </v-select>
      </v-flex>
      <v-flex xs12 md2 text-right pb-3 pt-8 pl-2 pr-1>
        <v-autocomplete
          clearable
          item-color="#005f32"
          color="#005f32"
          :items="organisationList"
          dense
          label="Organization"
          v-model="orgId"
          item-text="name"
          item-value="_id"
        >
        </v-autocomplete>
      </v-flex>

      <!-- <v-flex xs12 md2 pt-8 text-right pb-3 pt-6 pl-1>
        <v-autocomplete
          clearable
          item-color="#005f32"
          color="#005f32"
          :items="projects"
          dense
          label="Employee"
          v-model="project"
          item-text="name"
          item-value="_id"
        >
        </v-autocomplete>
      </v-flex> -->
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card elevation="0" shaped color="#D61A3C" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Amount to be Raised : <br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ donorbased.toBeRaised }}</span
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 text-left>
            <v-card elevation="5" shaped color="#0E26B1" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span
                    style="
                      font-family: poppinsregular;
                      color: white;
                      font-size: 15px;
                    "
                  >
                    Committed but not Confirmed :<br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ donorbased.notconfirmed }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card elevation="10" shaped color="#FFA500" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Committed and Confirmed : <br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ donorbased.committed }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card
              elevation="10"
              shaped
              color="rgb(0, 77, 26)"
              class="white--text"
            >
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Amount Recieved :<br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ donorbased.amountAchieved }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 text-right pt-2>
        <download-excel :data="myData" :fields="json_fields">
          <v-btn
            width="155px"
            dark
            style="text-transform: none"
            color="rgb(0, 77, 26)"
            >Download Excel</v-btn
          >
        </download-excel>
      </v-flex> -->
      <!-- <v-flex xs12 pt-2 v-if="flag">
        <div id="vuechart-example">
          <apexchart
            type="bar"
            height="430"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-flex> -->
      <v-flex xs12 v-if="flag">
        <div v-if="donorseries.length > 0">
          <center>
            <apexchart
              type="pie"
              width="480"
              :options="chartOptions4"
              :series="donorseries"
            >
            </apexchart>
          </center>
        </div>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 text-left pb-3 pt-6>
        <span style="font-family: poppinssemibold; font-size: 20px">
          EMPLOYEE PIECHART</span
        >
        <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
          <v-select
            item-color="#005f32"
            :items="years"
            item-text="fyear"
            item-value="_id"
            dense
            label="Year"
            v-model="year5"
          >
          </v-select>
        </v-flex>
        <!-- <span>{{item.targetDetails}}</span> -->
        <v-layout wrap>
          <v-flex pa-3 lg6 xs12 v-for="(item, i) in bardata" :key="i">
            <v-card class="rounded-xl">
              <v-layout wrap>
                <v-flex pa-4 xs12>
                  <span style="font-family: poppinssemibold">
                    EMPLOYEE NAME : {{ item.employeeData.name }}</span
                  >
                </v-flex>
                <!-- <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
        <v-select
          item-color="#005f32"
          :items="years"
          item-text="fyear"
          item-value="_id"
          dense
          label="Year"
          v-model="year4"
        >
        </v-select>
      </v-flex>

      <v-flex xs12 md2 text-right pb-3 pt-7 pl-2 pr-1>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDateFormattedMomentjs"
              clearable
              label="Select Date"
              readonly
              dense
              item-color="#005f32"
              outlined
              v-model="toDate"
              v-bind="attrs"
              v-on="on"
              @click:clear="from = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            @change="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card elevation="0" shaped color="#D61A3C" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Amount to be Raised : <br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{  }}</span
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 text-left>
            <v-card elevation="5" shaped color="#0E26B1" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular">
                    Committed but not confirmed :
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ piedata.CommittedNotConfirmed }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card elevation="10" shaped color="#FFA500" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Committed and Confirmed : <br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ piedata.Committed }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-1 style="font-family: poppinssemibold" text-left>
            <v-card elevation="10" shaped     color="rgb(0, 77, 26)" class="white--text">
              <v-layout pa-3>
                <v-flex>
                  <span style="font-family: poppinsregular"
                    >Amount Recieved :<br />
                  </span>
                  <span style="font-family: poppinssemibold"
                    >Rs. {{ piedata.Recived }}</span
                  >
                  <br />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex> -->
                <v-flex xs12 v-if="flag">
                  <div ref="chart" v-if="bardata.length > 0">
                    <center>
                      <apexchart
                        type="pie"
                        width="480"
                        :options="chartOptions5"
                        :series="series5[i]"
                      >
                      </apexchart>
                    </center>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      
    </v-layout>
    <v-layout wrap>
      <!-- <v-flex xs12 text-left pb-3 pt-6>
        <span style="font-family: poppinssemibold"> CONSOLIDATED REPORT</span>
      </v-flex> -->
      <!-- <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
        <v-select
          item-color="#005f32"
          :items="years"
          item-text="fyear"
          item-value="_id"
          dense
          label="Year"
          v-model="year2"
        >
        </v-select>
      </v-flex>
      <v-flex xs12 md2 text-right pb-3 pt-8 pl-2 pr-1>
        <v-autocomplete
          clearable
          item-color="#005f32"
          color="#005f32"
          :items="organisationList"
          dense
          label="Organization"
          v-model="orgId1"
          item-text="name"
          item-value="_id"
        >
        </v-autocomplete>
      </v-flex> -->
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 pt-4 text-left>
        <span style="font-family: poppinssemibold; font-size: 20px">
          EMPLOYEE COMPARISON GRAPH</span
        >
      </v-flex>
      <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
        <v-select
          item-color="#005f32"
          :items="years"
          item-text="fyear"
          item-value="_id"
          dense
          label="Year"
          v-model="year4"
        >
        </v-select>
      </v-flex>
      <v-flex xs12 md2 text-right pb-3 pt-8 pl-2 pr-1>
        <v-select
          item-color="#005f32"
          :items="months"
          item-text="fyear"
          item-value="_id"
          dense
          label="Month"
          v-model="month"
        >
        </v-select>
      </v-flex>
      <v-flex xs12 pt-2 v-if="flag">
        <div id="vuechart-example2" v-if="barlist.length > 0">
          <apexchart
            height="430"
            type="bar"
            :options="chartOptions6"
            :series="series6"
          ></apexchart>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>


<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
// import Analytics from "./analytics";
// import GraphData from "./graphData";

export default {
  components: {
    apexchart: VueApexCharts,
    // GraphData,
  },

  watch: {
    month() {
      this.barGraph();
    },
    year2() {
      this.donorBasedGraph();
    },
    mnth() {
      this.donorBasedGraph();
    },

    orgId() {
      this.donorBasedGraph();
    },
    year1() {
      this.graphData();
    },

    toDate() {
      this.getgraph();
    },
    project() {
      this.graphData();
    },
 

    year5() {
      this.getgraph2();
    },
    year4() {
      this.getgraph();
      this.barGraph();
    },
  },
  data() {
    return {
      amtAchieved: 0,
      amtAchieved1: 0,
      amtCommitted: 0,
      amtCommitted1: 0,
      orgId: null,
      donorbased: {},
      amtNotConfirmed1: 0,
      month: new Date().toLocaleString("default", { month: "long" }),
      amtNotConfirmed: 0,
      date1: new Date(),
      mnth: new Date().toLocaleString("default", { month: "long" }),
      organisationList: [],
      series5: [],
      series6: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      ORG: "",
      total: null,
      projects: [],
      piedata: [],
      // piedata1: {},
      // piedata2: {},
      // piedata3: {},
      toDate: null,
      bardata: [],
      barlist: [],
      months: [],
      from: null,
      series2: [],
      donorseries: [],
      filters: {
        convertDate: function (value) {
          var date = new Date(value);
          return date.toLocaleString();
        },
      },
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      series4: [],
      menu1: false,
      menu2: false,
      project: null,
      years: [],
      toBeraised: null,
      orgId1: null,
      piedata2: {},
      year5: null,
      year1: null,
      year2: null,
      year4: null,
      flag: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
      // download excel
      myData: [],
      excel: {
        amountAchieved: "",
        committed: "",
        month: "",
        notconfirmed: "",
        year1: "",
      },
      json_fields: {
        Amount_acheived: "amountAchieved",
        Committed: "committed",
        Month: "month",
        Not_confirmed: "notconfirmed",
        Year: "year1",
      },
      //
      chartOptions2: {
        colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        fill: {
          colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        },

        chart: {
          width: 480,
          type: "pie",
          toolbar: { show:true },
tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          },


        },
        labels: [
          "Amount to be Raised (Rs. )" ,
          "Committed but not Confirmed (Rs.)",
          "Committed and Confirmed (Rs.)",
          "Amount Received (Rs.)",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptions5: {
        colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        fill: {
          colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        },
        chart: {
          width: 380,
          type: "pie",
           toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          },
          autoSelected: 'zoom'
        },
        },
         toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          },
          autoSelected: 'zoom'
        },
        labels: [
          "Amount to be Raised  (Rs.)",
          "Committed but not Confirmed (Rs.)",

          " Committed and Confirmed (Rs.)",
          "Amount Recieved (Rs.)",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptions4: {
        colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        fill: {
          colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        },

        chart: {
          width: 380,
          type: "pie",
           toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          },
          autoSelected: 'zoom'
        },
        },
        labels: [
          "Amount to be Raised  (Rs.) ",
          " Committed but not Confirmed (Rs.)",
          "Committed and Confirmed (Rs.)",
          "Amount Received  (Rs.)",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      chartOptions3: {
        colors: ["#004d4d", "#004d1a", "red", "blue"],
        chart: {
          type: "bar",
          height: 430,
          width: "100%",
          id: "vuechart-example2",
        },
        plotOptions: {
          bar: {
            columnWidth: "11%",
            barWidth: 10,
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        fill: {
          colors: ["#004d4d", "#004d1a", "#f00", "#0000FF"],
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptions6: {
        colors: ["rgb(0, 77, 26)", "#FFA500"],
        
        chart: {
          type: "bar",
          height: 430,
          width: "100%",
          id: "vuechart-example2",
        },
        plotOptions: {
          bar: {
            columnWidth: "15%",
            barWidth: 10,
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -1,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        fill: {
                 colors: ["rgb(0, 77, 26)", "#FFA500"],

        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
      spacing: 5,

      series3: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#004d4d", "#004d1a", "#14141f"],
        chart: {
          type: "bar",
          height: 430,
          id: "vuechart-example",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },

      series: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      cards: [],
      dashCardsAdmin: [
        {
          name: "Total Project",
          value: null,
          icon: "mdi-note",
          route: "/Projects",
        },
        {
          name: "Total Organisation",
          value: null,
          icon: "mdi-locker-multiple",
          route: "/OrganisationList",
        },
        {
          name: "Employees",
          value: null,
          icon: "mdi-account",
          route: "/UserList",
        },
        {
          name: "Total Organisation Types",
          value: null,
          icon: "mdi-locker-multiple",
          route: "/OrganisationType",
        },
      ],
      dashCardsFinance: [
        {
          name: "Total Project",
          value: null,
          icon: "mdi-note",
          route: "/Projects",
        },
        // {
        //   name: "Total Organisation",
        //   value: null,
        //   icon: "mdi-locker-multiple",
        //   route: "/OrganisationList",
        // },
        {
          name: "Employees",
          value: null,
          icon: "mdi-account",
          route: "/UserList",
        },
        // {
        //   name: "Total Organisation Types",
        //   value: null,
        //   icon: "mdi-locker-multiple",
        //   route: "/OrganisationType",
        // },
      ],
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? this.date.format("dddd, MMMM Do YYYY") : "";
    },
    // computedDateFormattedDatefns() {
    //   return this.date ? format(parseISO(this.date), "EEEE, MMMM do yyyy") : "";
    // },
    userType() {
      return this.$store.state.userType;
    },
  },

  mounted() {
    this.barGraph();
    this.getOrganization();
    this.getData();
    // this.getgraph();
    // this.piechartdata()
    this.getYear();
    this.getMonth();
    if (this.userType == "SuperAdmin") {
      this.cards = this.dashCardsAdmin;
    } else if (this.userType == "Finance") {
      this.cards = this.dashCardsFinance;
    }
    // this.getProjects();
  },
  methods: {
    downloadChart() {
      const filename = 'my_chart_data.csv'
      this.chart.downloadCSV({ filename })
    },
    getMonth() {
      this.appLoading = true;
      axios({
        url: "get/all/month",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.months = response.data.data;
            // this.mnth = response.data.data[11];
            // this.mnth = response.data.data[11];
            // this.month = response.data.data[11];
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getYear() {
      this.appLoading = true;
      axios({
        url: "/fyear/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.years = response.data.data;
            this.year1 = response.data.data[1]._id;
            this.year4 = response.data.data[1]._id;
            this.year5 = response.data.data[1]._id;
            this.year2 = response.data.data[1]._id;

            this.graphData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/count",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dashCardsAdmin[0].value = response.data.projectcount;
            this.dashCardsAdmin[1].value = response.data.orgcount;
            this.dashCardsAdmin[2].value = response.data.employeecount;
            this.dashCardsAdmin[3].value = response.data.orgtypecount;
            this.dashCardsFinance[0].value = response.data.projectcount;
            this.dashCardsFinance[1].value = response.data.employeecount;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getOrganization() {
      this.appLoading = true;
      axios({
        url: "/organization/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;

          this.organisationList = response.data.data;
          // this.ORG = response.data.data[0]._id;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    graphData() {
      this.appLoading = true;
      this.flag = false;
      this.series2 = [];
      axios({
        method: "post",
        url: "/dashboard/graph/financialYearly",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          year1: this.year1,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.series2 = [];
            this.piedata2 = response.data.data;
            this.series2 = [
              this.piedata2.toBeRaised,
              this.piedata2.notconfirmed,
              this.piedata2.committed,
              this.piedata2.amountAchieved,
            ];
            this.flag = true;
        
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    donorBasedGraph() {
      this.appLoading = true;
      this.flag = false;
      this.donorseries = [];
      axios({
        method: "post",
        url: "/dashboard/graph/org/based",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          year2: this.year2,
          mnth: this.mnth,
          orgId: this.orgId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.donorseries = [];
            this.donorbased = response.data.pichart;
            this.donorseries = [
              this.donorbased.toBeRaised,
              this.donorbased.notconfirmed,
              this.donorbased.committed,
              this.donorbased.amountAchieved,
            ];

            this.flag = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    barGraph() {
      this.flag = false;
      axios({
        method: "post",
        url: "/dashboard/graph/employeeWise/comparisions",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          year4: this.year4,
          month: this.month,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.barlist = response.data.data;
            for (let i = 0; i < this.barlist.length; i++) {
              if (i == 0) {
                this.chartOptions6.xaxis.categories = [];
                this.series6[0].name = "";
                this.series6[0].data = [];
                this.series6[1].name = "";
                this.series6[1].data = [];
                  this.series6[2].name = "";
                this.series6[2].data = [];
                  this.series6[3].name = "";
                this.series6[3].data = [];
              }
              this.chartOptions6.xaxis.categories.push(
                this.barlist[i].employeeData.name
              );

              this.series6[0].name = "Amount Committed Current Month  (Rs.)";
              this.series6[0].data.push(
                this.barlist[i].targetDetailsCurrentMonth.committed
              );
              this.series6[1].name = "Amount Achieved Current Month (Rs.)";
              this.series6[1].data.push(
                this.barlist[i].targetDetailsCurrentMonth.amountAchieved
              );

               this.series6[2].name = "Amount Commited Previous Month (Rs.)";
              this.series6[2].data.push(
                this.barlist[i].previousMonth.committed
              );
                this.series6[3].name = "Amount Achieved Previous Month (Rs.)";
              this.series6[3].data.push(
                this.barlist[i].previousMonth.amountAchieved
              );
            }
            this.flag = true;
       
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getgraph() {
      this.flag = false;
      axios({
        method: "post",
        url: "/dashboard/graph/employeeWise",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          year4: this.year4,
          toDate: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.bardata = response.data.data;
            for (let i = 0; i < this.bardata.length; i++) {
              if (i == 0) {
                this.chartOptions3.xaxis.categories = [];
                this.series[0].name = "";
                this.series3[0].data = [];
                this.series3[1].name = "";
                this.series3[1].data = [];
                this.series3[2].name = "";
                this.series3[2].data = [];
                this.series3[3].name = "";
                this.series3[3].data = [];
                this.series5 = [];
              }
              this.chartOptions3.xaxis.categories.push(
                this.bardata[i].employeeData.name
              );
              this.series3[0].name = "Amount Achieved (Rs.)";
              this.series3[0].data.push(
                this.bardata[i].targetDetails.amountAchieved
              );
              this.series3[2].name = "Not Confirmed (Rs.)";
              this.series3[2].data.push(
                this.bardata[i].targetDetails.notconfirmed
              );
              this.series3[1].name = "Committed (Rs.)";
              this.series3[1].data.push(
                this.bardata[i].targetDetails.committed
              );
              this.series3[3].name = "To Be Raised";
              this.series3[3].data.push(this.bardata[i].targetDetails.total);
              this.series5 = [
                this.bardata[i].targetDetails.amountAchieved,
                this.bardata[i].targetDetails.notconfirmed,
                this.bardata[i].targetDetails.committed,
                this.bardata[i].targetDetails.total,
              ];
            }
            this.flag = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getgraph2() {
      this.flag = false;
      axios({
        method: "post",
        url: "/dashboard/graph/employeeWise",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          year5: this.year5,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.bardata = response.data.data;
            for (let i = 0; i < this.bardata.length; i++) {
              if (i == 0) {
                this.series5 = [];
              }
              this.series5.push([
                this.bardata[i].targetDetails.toBeRaised,

                this.bardata[i].targetDetails.commitedButNotconfirmed,
                this.bardata[i].targetDetails.committedAndConfirmed,
                this.bardata[i].targetDetails.amountRecived,
              ]);
            }
            this.flag = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-select {
  font-family: poppinsbold !important;
}
</style>